import { useEffect, useState } from 'react'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import terms from 'assets/terms'
import {
  Button, ButtonSize, ButtonStyle, InputSize, popModal, SelectInput, TextInput,
} from 'components'
import { Member, UserSearch } from 'types'
import { getUsersForEntites, patchTeamMembers, TeamSignal } from 'services'

import './AddTeamMembers.scss'

type Props = {
  teamId: string
}

export default function AddTeamMembers({ teamId }: Props) {
  const [results, setResults] = useState<UserSearch[]>()
  const [data, setData] = useState<Member[]>([])
  const [showMore, setShowMore] = useState(false)
  const [possibleUsers, setPossibleUsers] = useState<UserSearch[]>([])
  const team = TeamSignal.list.value.find(t => t.id === teamId)

  useEffect(() => {
    const entities = team?.entites?.map(entity => entity.id) || []
    if (entities.length > 0) {
      getUsersForEntites(entities).then(response => setPossibleUsers(response))
    } else {
      setPossibleUsers([])
    }
  }, [team])

  const handleSearch = (value: string) => {
    const sanitzedValue = value.toLowerCase()

    setResults(possibleUsers.filter(
      user => (user.username?.toLowerCase().includes(sanitzedValue)
      || user.email?.toLowerCase().includes(sanitzedValue))
      && !data.find(d => d.user === user.cerbere_id)
      && !team.users.find(u => u.id === user.cerbere_id),
    ).map(user => ({
      ...user,
      id: user.cerbere_id,
    })))
  }

  const handleSelect = (cerb: UserSearch) => (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation()
    setData(prev => [
      ...prev,
      {
        user: cerb.id,
        type: 'member',
        ...cerb,
      },
    ])
    setResults([])
  }

  const handleRoleChange = (userId: string) => (value: string) => {
    setData(prev => prev.map(user => {
      if (user.user === userId) {
        return {
          ...user,
          type: value,
        }
      }
      return user
    }))
  }

  const handleRemoveUser = (userId: string) => () => {
    setData(prev => prev.filter(user => user.user !== userId))
  }

  const handleValidate = async () => {
    await patchTeamMembers(team.id, data)
    popModal()
  }

  const renderUserList = data?.length > 0 && (
    <table className="users highlighted">
      <tbody>
        {data.slice(0, showMore ? data.length : 2).map(user => (
          <tr key={user.user} className="result">
            <td className="cp">{user.username}</td>
            <td className="name">{`${user.firstName} ${user.lastName}`}</td>
            <td className="email">{user.email}</td>
            <td className="role">
              <SelectInput
                defaultValue="member"
                options={[{
                  value: 'member',
                  label: 'Agent',
                }, {
                  value: 'leader',
                  label: 'Responsable',
                }]}
                onChange={handleRoleChange(user.user)}
                size={InputSize.xsmall}
              />
            </td>
            <td className="remove">
              <Button
                text=""
                icon={<DeleteIcon />}
                onClick={handleRemoveUser(user.cerbere_id)}
                size={ButtonSize.xsmall}
                style={ButtonStyle.borderLess}
              />
            </td>
          </tr>
        ))}
        {data.length > 2 && (
        <tr className="show-more">
          <td colSpan={4}>
            <Button
              text={showMore ? 'Voir moins' : 'Voir plus'}
              onClick={() => setShowMore(!showMore)}
              style={ButtonStyle.borderLess}
            />
          </td>
        </tr>
        )}
      </tbody>
    </table>
  )

  return (
    <div className="modal-add-members modal-content">
      <div className="header">
        <h2>{terms.Modals.AddTeamMembers.title}</h2>
      </div>
      <div className="search">
        <TextInput
          onChange={handleSearch}
          placeholder="Entrer un nom ou CP"
          LeftIcon={<SearchIcon />}
        />
        {results?.length > 0 && (
          <div className="results hide-scroll">
            <table>
              {results?.map(user => (
                <tr key={user.id} className="result" onClick={handleSelect(user)}>
                  <td className="cp">{user.username}</td>
                  <td className="name">{`${user.firstName} ${user.lastName}`}</td>
                  <td className="email">{user.email}</td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
      {renderUserList}
      <div className="actions">
        <Button text={terms.Common.cancel} onClick={popModal} style={ButtonStyle.light} />
        <Button
          text={terms.Common.save}
          onClick={handleValidate}
          isAsync
        />
      </div>
    </div>
  )
}
