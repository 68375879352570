import { useState } from 'react'
import terms from 'assets/terms'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import {
  Button, ButtonStyle, CreateTeamModal, ModalSignal,
} from 'components'
import Teams from './Teams'
import Sections from './Sections'

import './TeamsManagerPanel.scss'

type Tabs = 'teams' | 'sections'

export default function TeamsManagerPanel() {
  const tabs: Tabs[] = ['teams', 'sections']
  const [selected, setSelected] = useState<Tabs>('teams')

  const handleSelectTab = (tab: Tabs) => () => setSelected(tab)

  const handleCreateTeam = () => {
    ModalSignal.value = <CreateTeamModal />
  }

  const handleCreateSection = () => {
    // ModalSignal.value = <CreateSectionModal isSection />
  }

  return (
    <>
      <h3 className="panel-title teams-panel-title">{terms.Panels.TeamsManager.title}</h3>
      <div className="panel-actions teams-panel-actions">
        <div className="tabs flex-center">
          {tabs.map(tab => (
            <Button
              key={tab}
              className={selected === tab ? 'selected' : ''}
              text={terms.Panels.TeamsManager.tabs[tab]}
              style={ButtonStyle.borderLess}
              onClick={handleSelectTab(tab)}
            />
          ))}
        </div>
        <Button
          className="create-team"
          text={terms.Panels.TeamsManager[selected].create}
          icon={<PlusIcon />}
          onClick={selected === 'teams' ? handleCreateTeam : handleCreateSection}
        />
      </div>
      {selected === 'teams' && <Teams />}
      {selected === 'sections' && <Sections />}
    </>
  )
}
