import { Signal } from '@preact/signals-core'
import { polygon } from '@turf/helpers'
import MapComponent, { MapRef, ViewStateChangeEvent, MapLayerMouseEvent } from '@vis.gl/react-maplibre'
import 'maplibre-gl/dist/maplibre-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { mapStyles } from 'assets/map'
import { useEffect, useRef, useState } from 'react'
import {
  MapDataSignal, INTERACTIVE_LAYER_IDS, loadImages, loadMissingImage, HOVER_ONLY_LAYER_IDS,
  MAPBOX_LAYER_IDS,
} from 'services'
import { filterError, transformRequest } from 'utils'
import { Loader } from 'components'
import { ContentType } from 'types'
import { PanelSignal } from 'pages/home/panels/PanelsManager'
import InfoListPanel from 'pages/home/panels/info/InfoListPanel'
import InfoSinglePanel from 'pages/home/panels/info/InfoSinglePanel'
import { MapLibreEvent } from 'maplibre-gl'
import GeoLayers from './GeoLayers'
import SchLayers from './SchLayers'
import MapControls from './controls/MapControls'

type MapProps = {
  data: Signal<Omit<ContentType, 'id'>>
  mapId: string
}

export default function Map({ data, mapId }: MapProps) {
  const [loadingTiles, setLoadingTiles] = useState(true)
  const mapRef = useRef<MapRef>()
  const map = mapRef.current
  const { type, vp } = data.value

  useEffect(() => {
    if (map) {
      const onStyleImageMissing = (e: {id: string}) => {
        loadMissingImage(map, e.id)
      }
      const onStyleDataLoading = e => {
        // Skip displaying loading spinner for geojson sources
        // We only want to display the spinner when a request in pending
        if (e.source.type === 'geojson') return
        setLoadingTiles(true)
      }
      map.on('styleimagemissing', onStyleImageMissing)
      map.on('sourcedataloading', onStyleDataLoading)
      return () => {
        map.off('styleimagemissing', onStyleImageMissing)
        map.off('sourcedataloading', onStyleDataLoading)
      }
    } return undefined
  }, [map])

  const handleMove = (e: ViewStateChangeEvent) => {
    const newViewState = e.viewState
    const newVs = { ...newViewState }
    const bounds = map?.getMap()?.getBounds()
    if (bounds) {
      const bbox = polygon([[
        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
        [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
      ]]).geometry
      data.value = { type, vp: newVs, bbox }
    } else {
      data.value = { type, vp: newVs }
    }
  }

  const handleMouseEnter = (e: MapLayerMouseEvent) => {
    MapDataSignal.hoveredObjects.value = [...e.features]
    if (map) {
      map.getMap().getCanvas().style.cursor = 'pointer'
    }
  }

  const handleMouseLeave = () => {
    MapDataSignal.hoveredObjects.value = []
    if (map) {
      map.getMap().getCanvas().style.cursor = 'inherit'
    }
  }

  const handleClickFeatures = (e: MapLayerMouseEvent) => {
    MapDataSignal.targetedObject.value = undefined
    const filteredFeature = e.features?.filter(
      (feature, index, arr) => arr.findIndex(f => f.properties.id === feature.properties.id) === index,
    ).filter(feature => !HOVER_ONLY_LAYER_IDS.includes(feature.layer.id))

    const borderLabel = e.features?.find(feature => (
      [MAPBOX_LAYER_IDS.utmLabel, MAPBOX_LAYER_IDS.priLabel, MAPBOX_LAYER_IDS.zpLabel, MAPBOX_LAYER_IDS.infrapoleLabel]
        .includes(feature.layer.id)))
    if (borderLabel) MapDataSignal.targetedObject.value = borderLabel
    if (filteredFeature?.length === 0) return
    if (filteredFeature?.length === 1) {
      PanelSignal.value = (
        <InfoSinglePanel feature={filteredFeature[0]} />
      )
    } else if (filteredFeature?.length > 1) {
      PanelSignal.value = (
        <InfoListPanel data={e.features.filter(
          (feature, index, arr) => arr.findIndex(f => f.properties.id === feature.properties.id) === index,
        )}
        />
      )
    }
  }

  const handleLoad = (e: MapLibreEvent) => {
    const currentMap = e.target
    loadImages(e)
    currentMap.scrollZoom.setWheelZoomRate(1 / 100)
  }

  return (
    <>
      {loadingTiles && <div className="content-loading-wrapper"><Loader variant="small" /></div>}
      <MapComponent
        id={mapId}
        {...(vp?.latitude && { latitude: vp.latitude })}
        {...(vp?.longitude && { longitude: vp.longitude })}
        {...(vp?.zoom && { zoom: vp.zoom })}
        {...(vp?.bearing && { bearing: vp.bearing })}
        {...(vp?.pitch && { pitch: vp.pitch })}
        transformRequest={transformRequest}
        interactiveLayerIds={INTERACTIVE_LAYER_IDS}
        initialViewState={vp}
        ref={mapRef}
        mapStyle={type === 'geo' ? mapStyles.geo : mapStyles.sch}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClickFeatures}
        onIdle={() => { setLoadingTiles(false) }}
        onError={filterError}
        onMove={handleMove}
        onLoad={handleLoad}
      >
        {type === 'geo' ? <GeoLayers /> : <SchLayers />}
        <MapControls data={data} />
      </MapComponent>
    </>
  )
}
