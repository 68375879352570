import { layerMenu } from 'assets/layers'
import terms from 'assets/terms'
import { MenuLayer } from 'types'
import {
  allIndexesSelected,
  getSlugsForIndexes,
  handleLayerMenuSelect,
  isSomeChildSelected,
  LayersSignal,
  toggleAllIndexes,
} from 'services/layers'
import { CheckInput, RadioInput, Accordion, Tooltip } from 'components'

import './LayerPanel.scss'

export default function LayerPanel() {
  const getInputType = (type: string, key: string, label: string, tooltip: string) => {
    const Component = type === 'radio' ? RadioInput : CheckInput
    const isChecked = getSlugsForIndexes(LayersSignal.value).includes(key)

    return (
      <>
        <Component
          label=""
          defaultValue={isChecked}
          onChange={() => handleLayerMenuSelect(key, type)}
          indeterminate={isSomeChildSelected(key)}
        />
        <Tooltip placement="top" label={tooltip}>
          <p className="label">
            {label}
            {['center', 'utm', 'zp', 'infrapole'].includes(key) && (
            <>
              <br />
              <span style={{ fontSize: '12px', color: '#A3ADC2', fontWeight: '900' }}>
                En cours de consolidation
              </span>
            </>
            )}
          </p>
        </Tooltip>
      </>
    )
  }

  const getEntry = (entry: [string, string | MenuLayer], parentType = 'checkbox', parentLabels = null) => {
    const [key, value] = entry

    if (typeof value === 'string') return getInputType(parentType, key, value, parentLabels?.[key])

    const { type = 'checkbox', tooltip, ...subEntries } = value
    const categoryType = parentType || type
    const accordionTitleComponent = (type === 'checkbox' || type === 'radio-child')
      ? getInputType(categoryType, key, key, parentLabels?.[key])
      : key

    return (
      <Accordion title={accordionTitleComponent} defaultExpanded={false}>
        {Object.entries(subEntries).map(subEntry => (
          <div key={subEntry[0]}>{getEntry(subEntry, type, tooltip)}</div>
        ))}
      </Accordion>
    )
  }

  return (
    <>
      <h3 className="panel-title">{terms.Header.layers}</h3>
      <div className="layer-panel panel hide-scroll">
        <div className="flex-center">
          <CheckInput
            label=""
            defaultValue={allIndexesSelected()}
            indeterminate={LayersSignal.value.length > 0}
            onChange={toggleAllIndexes}
          />
          <p className="label">
            {allIndexesSelected() ? terms.Common.unselectAll : terms.Common.selectAll}
          </p>
        </div>
        {Object.entries(layerMenu).map(item => (
          <div key={item[0]}>{getEntry(item)}</div>
        ))}
      </div>
    </>
  )
}
